@use '@angular/material' as mat;

/* You can add global styles to this file, and also import other style files */


:root {

  --main-color: #171751;
  --alternate-color: #53cccc;

}

.blank-container {

  background-image: url(/assets/images/background/login-register.jpg);

}


.navbar-brand {

  img {

    height: 40px;

  }

}

// http://mcg.mbitson.com/#!?mcgpalette0=%233f51b5

@import '@angular/material/theming';

$primary-palette: (
  50 : #e3e3ea,
  100 : #b9b9cb,
  200 : #8b8ba8,
  300 : #5d5d85,
  400 : #3a3a6b,
  500 : #171751,
  600 : #14144a,
  700 : #111140,
  800 : #0d0d37,
  900 : #070727,
  A100 : #6363ff,
  A200 : #3030ff,
  A400 : #0000fc,
  A700 : #0000e3,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$secondary-palette: (
  50 : #eaf9f9,
  100 : #cbf0f0,
  200 : #a9e6e6,
  300 : #87dbdb,
  400 : #6dd4d4,
  500 : #53cccc,
  600 : #4cc7c7,
  700 : #42c0c0,
  800 : #39b9b9,
  900 : #29adad,
  A100 : #eeffff,
  A200 : #bbffff,
  A400 : #88ffff,
  A700 : #6effff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$primary: mat.define-palette($primary-palette, 600);
$accent: mat.define-palette($secondary-palette, 500);
$warn: mat.define-palette(mat.$red-palette, 500);
$theme: mat.define-light-theme($primary, $accent, $warn);

@include mat.all-legacy-component-themes($theme);
